@import "variables";

.content {
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (max-width: 992px) {
    height: calc((var(--vh, 1vh) * 100) - 136px);
  }
  @media (min-width: 992px) {
    height: calc((var(--vh, 1vh) * 100) - 158px);
  }

  .contentTitle {
    font-family: $font-title;
    font-size: 45px;
    font-weight: 600;
    margin-top: auto;

    @media (max-width: 576px) {
      font-size: 36px;
    }
  }
  .contentDescription {
    margin: 12px auto 0 auto;
    max-width: 700px;
    font-weight: 500;
  }
  .contentImage {
    padding: 24px;
    box-sizing: border-box;
    position: relative;
    max-width: 380px;
    max-height: 380px;
    width: 100%;
    height: 100%;
    margin: 32px auto auto auto;

    @media (max-width: 576px) {
      max-width: 290px;
      max-height: 290px;
    }
  }
}